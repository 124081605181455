/* eslint-disable no-undef */
/* global google */
import "./styles.scss";

import React, { useState, useCallback, memo } from "react";
import { withPrefix } from "gatsby";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import classnames from "classnames";

import markers from "libs/legend_data";

const mapOptions = {
    styles: [
        {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#e9dde7",
                },
            ],
        },
        {
            featureType: "landscape.natural.landcover",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#e3dae1",
                },
            ],
        },
        {
            featureType: "landscape.natural.terrain",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#7a587d",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#f5edf4",
                },
            ],
        },
        {
            featureType: "poi.attraction",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "on",
                },
            ],
        },
        {
            featureType: "poi.business",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#b684ac",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                {
                    lightness: 100,
                },
                {
                    visibility: "simplified",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#dbc5da",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    visibility: "on",
                },
                {
                    color: "#C6E2FF",
                },
            ],
        },
    ],
};

const center = { lat: 50.82154977858756, lng: 15.539882355820753 };

const LocationMap = () => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDuPbNT2C1i1G0Pd26MgNx5Zu5hUskXxQI",
    });

    const [selectedMarker, setSelectedMarker] = useState(null);

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);

        const allMarkers = [];

        markers?.map(({ items }) =>
            items?.map(({ lat, lng }) => allMarkers?.push({ lat, lng }))
        );

        for (let loc of allMarkers)
            bounds.extend({
                lat: parseFloat(loc.lat),
                lng: parseFloat(loc.lng),
            });

        // map.fitBounds(bounds);
    }, []);

    if (!isLoaded) {
        return <></>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "800px" }}
            zoom={11}
            center={center}
            options={{
                ...mapOptions,
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
            }}
            onLoad={onLoad}
        >
            <Marker
                icon={{
                    url: withPrefix("/ico-pointer.png"),
                }}
                position={center}
            />
            {markers?.map(({ variant, items }) =>
                items?.map((item, index) => (
                    <Marker
                        key={index}
                        position={{ lat: item?.lat, lng: item?.lng }}
                        label={{
                            className: classnames("custom-marker", {
                                [`custom-marker--${variant}`]: !!variant,
                            }),
                            text: (index + 1).toString(),
                        }}
                        icon={{
                            anchor: new window.google.maps.Point(18, 18),
                            size: new window.google.maps.Size(36, 36),
                            url: withPrefix("/ico-pointer-blank.png"),
                        }}
                        onMouseOver={() => setSelectedMarker(item)}
                        onMouseOut={() => setSelectedMarker(null)}
                    />
                ))
            )}
            {selectedMarker !== null && (
                <InfoWindow
                    position={{
                        lat: selectedMarker?.lat,
                        lng: selectedMarker?.lng,
                    }}
                    options={{
                        pixelOffset: new google.maps.Size(0, -9),
                    }}
                >
                    <div className="window-wrapper">
                        <label>{selectedMarker?.name}</label>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

export default memo(LocationMap);
